import React from "react"
import { Link } from "gatsby"
import Image from "react-bootstrap/Image"
import Logo from "../images/logo.png"

export default function Navbar() {
  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-light bg-transparent">
        <div className="container">
          <Link to="/">
            <Image src={Logo} fluid />
          </Link>
          <button className="navbar-toggler" style={{border:'none'}} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
              </svg>
            </icon>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div id="navbar-mobile" className="navbar-nav ms-auto">
              <hr />
              <Link className="nav-link" to="/">
                HOME
              </Link>
              <Link className="nav-link" to="/#news">
                NEWS
              </Link>
              <Link className="nav-link" to="/#works">
                WORK
              </Link>
              <Link className="nav-link" to="/#contact">
                CONTACT
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
