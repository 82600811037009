import React from "react"
import { Link } from "gatsby"
import "../components/main.css"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Card(props) {
    return (
        <Link style={{textDecoration:"none"}} to={props.link}>
            <div id="card-border" className="card mx-3 text-dark bg-transparent">
                <GatsbyImage image={props.image} alt={props.cardTitle} />
                <div className="card-body">
                    <p className="card-text gold-color">{props.cardTime}</p>
                    <h5 className="card-title">{props.cardTitle}</h5>
                    <p className="card-text">{props.cardText}</p>
                </div>
            </div>
        </Link>
    );
  }