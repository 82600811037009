import React  from 'react';
import Moment from 'react-moment';

export default class MyComponent extends React.Component {
    render() {
        return (
            <Moment fromNow interval={60000}>
                {this.props.time}
            </Moment>
        );
    }
}